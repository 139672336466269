import { init_language } from '@/utils/language'

const config = {
  host  : window.location.hostname,
  lang  : init_language('en'),
  email : 'support@finda.net',
  set_email (src: string): string {
    return src.replaceAll('support@finda.net', this.email)
  },
  gtag: '',
  stripe: 'pk_test_51MFeGFK2UMrYUtMHyg0RhmI2eQqnfwEKPOQ2zyMNIKXE86R2uaLatxdEx68wRmER4SuN94w5jAZ9IudDCod3GIt400uDtqKnol'
}


export { config }
  
